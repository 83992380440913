import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';


class SidenavContent extends Component {
    componentDidMount() {
        const {history} = this.props;
        const that = this;
        const pathname = `${history.location.pathname}`;// get current path

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {

                const parentLiEle = that.closest(this, 'li');
                if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
                    event.stopPropagation();

                    if (menuLi[i].classList.contains('open')) {
                        menuLi[i].classList.remove('open', 'active');
                    } else {
                        menuLi[i].classList.add('open', 'active');
                    }
                } else {
                    for (let j = 0; j < menuLi.length; j++) {
                        const parentLi = that.closest(this, 'li');
                        if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                            menuLi[j].classList.remove('open');
                        } else {
                            if (menuLi[j].classList.contains('open')) {
                                menuLi[j].classList.remove('open');
                            } else {
                                menuLi[j].classList.add('open');
                            }
                        }
                    }
                }
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    componentWillReceiveProps(nextProps) {
        const {history} = nextProps;
        const pathname = `${history.location.pathname}`;// get current path

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        return (
            <CustomScrollbars className=" scrollbar child-auto-scroll">

                <ul className="nav-menu">
                    <li className="menu no-arrow">
                        <NavLink to="/panel/dashboard">
                            <div className="side-bar-content">
                                <img alt="alt" className="side-bar-icon" src={require("../../assets/images/mars/dashboard-svgrepo-com.svg")}/>
                                <span className="nav-text"><IntlMessages id="sidebar.dashboard"/></span>

                            </div>
                              </NavLink>
                    </li>
                    {/*<li className="menu no-arrow">*/}
                    {/*    <NavLink to="/panel/authorization">*/}
                    {/*        <div className="side-bar-content">*/}
                    {/*        <img alt="alt" className="side-bar-icon" src={require("../../assets/images/V2/auth_icon.svg")}/>*/}
                    {/*        <span className="nav-text"><IntlMessages id="sidebar.authentication"/></span>*/}
                    {/*        </div>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    {/*<li className="menu no-arrow">*/}
                    {/*    <NavLink to="/panel/creditcard">*/}
                    {/*        <div className="side-bar-content">*/}
                    {/*            <img alt="alt" className="side-bar-icon" src={require("../../assets/images/V2/credit-card-icon.svg")}/>*/}

                    {/*            <span className="nav-text"><IntlMessages id="sidebar.credit_card-record"/></span>*/}
                    {/*        </div>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    <li className="menu collapse-box">
                        <a>
                            <div className="side-bar-content">
                                <img alt="alt" className="side-bar-icon" src={require("../../assets/images/mars/wallet-svgrepo-com (1).svg")}/>

                                <span className="nav-text">
             <IntlMessages id="sidebar.wallet"/>
              </span>
                            </div>
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to="/panel/wallet/deposit">
                                    <span className="nav-text"><IntlMessages id="sidebar.deposit"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/panel/wallet/withdraw">
                                    <span className="nav-text"><IntlMessages id="sidebar.withdraw"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/panel/wallet/transactions">
                                    <span className="nav-text"><IntlMessages id="sidebar.transactions"/></span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="menu collapse-box">
                        <a>
                            <div className="side-bar-content">
                            <img alt="alt" className="side-bar-icon" src={require("../../assets/images/mars/transaction-svgrepo-com (1).svg")}/>
                            <span className="nav-text">
            <IntlMessages id="sidebar.trade"/>
              </span>
                            </div>
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to="/panel/exchange/shopping">
                                    <span className="nav-text"><IntlMessages id="sidebar.new_order"/></span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to="/panel/exchange/transactions">
                                    <span className="nav-text"><IntlMessages id="sidebar.orders_list"/></span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/panel/referral">
                            <div className="side-bar-content">
                                <img alt="alt" className="side-bar-icon" src={require("../../assets/images/mars/invite-svgrepo-com.svg")}/>
                            <span className="nav-text"><IntlMessages id="sidebar.referral"/></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/panel/notices">
                            <div className="side-bar-content">
                            <img alt="alt" className="side-bar-icon" src={require("../../assets/images/mars/bell-svgrepo-com.svg")}/>
                            <span className="nav-text"><IntlMessages id="sidebar.notices"/></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/panel/setting">
                            <div className="side-bar-content">
                                <img alt="alt" className="side-bar-icon" src={require("../../assets/images/mars/person-male-svgrepo-com.svg")}/>

                                <span className="nav-text"><IntlMessages id="sidebar.user_settings"/></span>
                            </div>
                        </NavLink>
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/panel/support">
                            <div className="side-bar-content">
                                <img alt="alt" className="side-bar-icon" src={require("../../assets/images/mars/support-svgrepo-com.svg")}/>

                                <span className="nav-text"><IntlMessages id="sidebar.support"/></span>
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </CustomScrollbars>
        );
    }
}

export default withRouter(SidenavContent);
