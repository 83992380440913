import  React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER
} from '../../../constants/ActionTypes';

import IntlMessages from 'util/IntlMessages';
import {
    authModeSelector,
    setDarkTheme,
    switchAuthDialog,
    switchLanguage,
    toggleCollapsedNav,
    userSignOut
} from "../../../actions";
import HeaderMenu from "./HeaderMenu";
import ImageStorage from "../../../assets/ImageStorage";
import Drawer from "@material-ui/core/Drawer";
import CollapsedMenu from "./CollapsedMenu";
import LanguageSwitcher from "../../../containers/components/LanguageSwitcher";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import Button from "@material-ui/core/Button";


class Header extends React.Component {

    onAppNotificationSelect = () => {
        this.setState({
            // appNotification: !this.state.appNotification
        })
    };
    onMailNotificationSelect = () => {
        this.setState({
            mailNotification: !this.state.mailNotification
        })
    };
    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
        })
    };
    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox
        })
    };
    onAppsSelect = () => {
        this.setState({
            apps: !this.state.apps
        })
    };
    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo
        })
    };
    handleRequestClose = () => {
        this.setState({
            langSwitcher: false,
            userInfo: false,
            mailNotification: false,
            // appNotification: false,
            searchBox: false,
            apps: false
        });
    };
    handleCloseNotices = (e) => {

        this.setState({appNotification: !this.state.appNotification});
    }
    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };
    Apps = () => {
        return (
            <ul className="jr-list jr-list-half">
                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/calendar/basic">
                        <i className="zmdi zmdi-calendar zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.calendar.basic"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/to-do">
                        <i className="zmdi zmdi-check-square zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.appModule.toDo"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/mail">
                        <i className="zmdi zmdi-email zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.appModule.mail"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/chat">
                        <i className="zmdi zmdi-comment zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.appModule.chat"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/contact">
                        <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.appModule.contact"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/">
                        <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw"/>
                        <span className="jr-list-text">Add New</span>
                    </Link>
                </li>
            </ul>)
    };

    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            searchBox: false,
            searchText: '',
            mailNotification: false,
            userInfo: false,
            langSwitcher: false,
            appNotification: false,
        }
        this.handleCloseNotices = this.handleCloseNotices.bind(this);
    }

    updateSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
        });
    }

    changeDarkMode = (setDark) => {
        const {darkTheme} = this.props;

        if(setDark !== darkTheme) {
            this.props.setDarkTheme();
            const body = document.body.classList;
            body.toggle('dark-theme')
        }
    }

    render() {
        const {currentUser, drawerType, homeNavigationStyle, horizontalNavPosition, darkTheme, navCollapsed, locale, token} = this.props;
        let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : '';
        let type = 'temporary';
        return (
            <AppBar className={`app-main-header ${(homeNavigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
                {/*<div className="broadcast" />*/}
                <Toolbar className="app-toolbar home-header" disableGutters={false}>

                    {homeNavigationStyle === HORIZONTAL_NAVIGATION ?
                        <div className="d-block d-md-none pointer header-burger-icon" onClick={this.onToggleCollapsedNav}>
                            <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
                        </div>
                        :
                        <IconButton className={`jr-menu-icon header-burger-icon ${drawerStyle}`} aria-label="Menu"
                                    onClick={this.onToggleCollapsedNav}>
                            <span className="menu-icon"/>
                        </IconButton>
                    }




                    {/*<SearchBox styleName="d-none d-lg-block" placeholder=""*/}
                    {/*           onChange={this.updateSearchText.bind(this)}*/}
                    {/*           value={this.state.searchText}/>*/}
                    {(homeNavigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
                    <HeaderMenu/>}

                    <ul className="header-notifications list-inline ml-auto">
                        {/*<li className="list-inline-item">*/}
                        {/*  <Dropdown*/}
                        {/*    className="quick-menu app-notification"*/}
                        {/*    isOpen={this.state.apps}*/}
                        {/*    toggle={this.onAppsSelect.bind(this)}>*/}

                        {/*    <DropdownToggle*/}
                        {/*      className="d-inline-block"*/}
                        {/*      tag="span"*/}
                        {/*      data-toggle="dropdown">*/}
                        {/*      <span className="app-notification-menu">*/}
                        {/*        <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>*/}
                        {/*        <span>Apps</span>*/}
                        {/*      </span>*/}
                        {/*    </DropdownToggle>*/}

                        {/*    <DropdownMenu>*/}
                        {/*      {this.Apps()}*/}
                        {/*    </DropdownMenu>*/}
                        {/*  </Dropdown>*/}
                        {/*</li>*/}
                        {/*<li className="d-inline-block d-lg-none list-inline-item">*/}
                        {/*  <Dropdown*/}
                        {/*    className="quick-menu nav-searchbox"*/}
                        {/*    isOpen={this.state.searchBox}*/}
                        {/*    toggle={this.onSearchBoxSelect.bind(this)}>*/}

                        {/*    <DropdownToggle*/}
                        {/*      className="d-inline-block"*/}
                        {/*      tag="span"*/}
                        {/*      data-toggle="dropdown">*/}
                        {/*      <IconButton className="icon-btn">*/}
                        {/*        <i className="zmdi zmdi-search zmdi-hc-fw"/>*/}
                        {/*      </IconButton>*/}
                        {/*    </DropdownToggle>*/}

                        {/*    <DropdownMenu right className="p-0">*/}
                        {/*      <SearchBox styleName="search-dropdown" placeholder=""*/}
                        {/*                 onChange={this.updateSearchText.bind(this)}*/}
                        {/*                 value={this.state.searchText}/>*/}
                        {/*    </DropdownMenu>*/}
                        {/*  </Dropdown>*/}
                        {/*</li>*/}
                        <li className="list-inline-item">
                            <Dropdown
                                className="quick-menu"
                                isOpen={this.state.langSwitcher}
                                toggle={this.onLangSwitcherSelect.bind(this)}>

                                <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown">
                                    <IconButton className="icon-btn" >
                                        IR
                                        <i className={`flag flag-24 flag-${locale.icon}`}/>
                                    </IconButton>
                                </DropdownToggle>

                                <DropdownMenu right className="w-50 d-none">
                                    <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                                      handleRequestClose={this.handleRequestClose}/>
                                </DropdownMenu>
                            </Dropdown>


                        </li>
                        {/*<li onClick={(e) => darkTheme ? this.changeDarkMode(false) : this.changeDarkMode(true)} className="list-inline-item app-tour">*/}
                        {/*    {ImageStorage.header.darkMode(darkTheme, 'mode-btn')}*/}
                        {/*</li>*/}

                        {/*<li className="list-inline-item app-tour">*/}
                        {/*    <Dropdown*/}
                        {/*        className="quick-menu"*/}
                        {/*        isOpen={this.state.appNotification}*/}
                        {/*        toggle={this.handleCloseNotices}>*/}

                        {/*        <DropdownToggle*/}
                        {/*            className="d-inline-block"*/}
                        {/*            tag="span"*/}
                        {/*            data-toggle="dropdown">*/}
                        {/*            <IconButton size="medium" className="icon-btn">*/}
                        {/*                {this.props.hasUnseenUserNotice ?*/}
                        {/*                    <Badge  badgeContent={this.props.unSeenNoticesCount} color={'error'}>*/}
                        {/*                        <NotificationsActiveIcon />*/}
                        {/*                    </Badge>*/}
                        {/*                    :*/}
                        {/*                    <Badge invisible={true}>*/}
                        {/*                        <NotificationsActiveIcon/>*/}
                        {/*                    </Badge>*/}
                        {/*                }*/}

                        {/*            </IconButton>*/}
                        {/*        </DropdownToggle>*/}

                        {/*</li>*/}
                        {/*<li className="list-inline-item mail-tour">*/}
                        {/*  <Dropdown*/}
                        {/*    className="quick-menu"*/}
                        {/*    isOpen={this.state.mailNotification}*/}
                        {/*    toggle={this.onMailNotificationSelect.bind(this)}*/}
                        {/*  >*/}
                        {/*    <DropdownToggle*/}
                        {/*      className="d-inline-block"*/}
                        {/*      tag="span"*/}
                        {/*      data-toggle="dropdown">*/}

                        {/*      <IconButton className="icon-btn">*/}
                        {/*        <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw"/>*/}
                        {/*      </IconButton>*/}
                        {/*    </DropdownToggle>*/}


                        {/*    <DropdownMenu right>*/}
                        {/*      <CardHeader styleName="align-items-center"*/}
                        {/*                  heading={<IntlMessages id="mailNotification.title"/>}/>*/}
                        {/*      <MailNotification/>*/}
                        {/*    </DropdownMenu>*/}
                        {/*  </Dropdown>*/}
                        {/*</li>*/}

                        {homeNavigationStyle === HORIZONTAL_NAVIGATION &&
                            token === null ?
                            <div className="sign-buttons">
                                <Button className="sign-in-btn" onClick={() => {
                                    this.props.switchAuthDialog({state: true});
                                    this.props.authModeSelector("login");
                                }}
                                        disableRipple>
                                    <img src={require("../../../assets/images/mars/sign-in-alt-2-svgrepo-com.svg")}/>
                                    ورود به سایت
                                </Button>
                                <Button className="sign-up-btn" onClick={() => {
                                    this.props.switchAuthDialog({state: true});
                                    this.props.authModeSelector("register");
                                }}
                                        disableRipple>
                                <img src={require("../../../assets/images/mars/person-svgrepo-com.svg")}/>

                                    ثبت نام
                                </Button>
                            </div>
                            :
                            <>

                                <div className="sign-infos">

                                    {currentUser.email ?
                                        <div className="user-greeting">
                                            <div className="greeting">
                                                !خوش آمدی
                                            </div>
                                            <div className="username">
                                                {currentUser.email}
                                            </div>

                                        </div>
                                        :
                                        currentUser.phone_number ?
                                            <div className="user-greeting">
                                                <div className="greeting">
                                                    !خوش آمدی
                                                </div>
                                                <div className="username">
                                                    {currentUser.phone_number}
                                                </div>

                                            </div>
                                            :
                                            ""}
                                </div>
                                <Button className="log-out-btn" onClick={() => this.props.userSignOut()}>
                                    <>
                                        <img src={require("../../../assets/images/mars/offWhite.svg")}/>
                                        <div className="title">
                                            خروج
                                        </div>
                                    </>
                                </Button>
                            </>

                        }

                        {/*<li className="list-inline-item user-nav">*/}
                        {/*   <Link to="panel" className="header-btn">*/}
                        {/*       <IntlMessages id="button-go_to_panel"/>*/}
                        {/*   </Link>*/}
                        {/*</li>*/}
                        <Link className="app-mobile-logo" to="/">
                            <img src={require("../../../assets/images/mars/Changiny.png")}/>
                            {/*{ImageStorage.header.logo(darkTheme)}*/}
                            {/*<img alt="alt" style={{width: "88px"}} src={darkTheme ? require("../../../assets/images/logo/Logo.svg") : require("../../../assets/images/logo/LogoLight.svg")} alt="IRExchanger" title="IRExchanger"/>*/}
                        </Link>
                    </ul>

                    {/*<div className="ellipse-shape"></div>*/}
                    <Link className="app-logo" to="/">
                        <img src={require("../../../assets/images/mars/Changiny.png")}/>
                        {/*{ImageStorage.header.logo(darkTheme)}*/}
                        {/*<img alt="alt" style={{width: "88px"}} src={darkTheme ? require("../../../assets/images/logo/Logo.svg") : require("../../../assets/images/logo/LogoLight.svg")} alt="IRExchanger" title="IRExchanger"/>*/}
                    </Link>


                </Toolbar>
                <div className={`app-sidebar  ${drawerStyle}`}>

                    <Drawer className="app-sidebar-content"
                            variant={type}
                            open={type.includes('temporary') ? navCollapsed : true}
                            onClose={this.onToggleCollapsedNav}
                            classes={{
                                paper: 'side-nav',
                            }}
                    >
                        <>
                            <div className="logo-wrapper">
                                <img alt="alt" src={darkTheme ? require("../../../assets/images/logo/Logo.svg") : require("../../../assets/images/logo/LogoLight.svg")}/>
                            </div>
                            <CollapsedMenu/>

                        </>



                    </Drawer>
                </div>
            </AppBar>
        );
    }

}


const mapStateToProps = ({settings, notice, auth}) => {
    const {drawerType, locale, homeNavigationStyle, horizontalNavPosition, darkTheme, navigationStyle,
        navCollapsed,
        width} = settings;
    const {token, currentUser} = auth;
    const {hasUnseenUserNotice, unSeenNoticesCount} = notice;
    return {drawerType, locale, homeNavigationStyle, horizontalNavPosition, hasUnseenUserNotice, unSeenNoticesCount, darkTheme, navigationStyle,
        navCollapsed,
        width, token, currentUser}
};

export default withRouter(connect(mapStateToProps, {userSignOut, toggleCollapsedNav, switchLanguage, setDarkTheme, switchAuthDialog, authModeSelector})(Header));