import React from "react";
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {connect} from "react-redux";
import {switchBuyDialog, switchSellDialog} from "../../../actions/CurrencyResource";
// import '../../../../assets/css/formStyle.css'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {DIGITAL_CURRENCIES, MOBILE_MAX_WIDTH, STATUS} from "../../../panel/routes/data/constants";
import {
    currencySymbol,
    fa2enNumbers,
    getCurrencySupply,
    roundDown,
    roundUp,
    stepPrecision,
    translate
} from "../../../util/Utilities";
// import "../../../../assets/css/main.css"
import {Card, InputAdornment, Popper} from "@material-ui/core";

import {authModeSelector, switchAuthDialog} from "../../../actions";
import IntlMessages from "../../../util/IntlMessages";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";



const ACCEPTED = [STATUS.ACCEPTED_BY_ADMIN, STATUS.ACCEPTED_BY_SYSTEM, STATUS.ACCEPTED_BY_EX_API];
const PopperMy = function (props) {
    return <Popper {...props} className='panel-select-currency-popover' placement="bottom-start" />;
};
function TabContainer(props) {
    return (
        <div className="tab-container">
            {props.children}
        </div>
    );
}

class CurrencyConverter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            inputs: {
                conversion: 1,
                origin_currency: 12,
                destination_currency: 1195,
                origin_amount: 0,
                destination_amount: 0,
                selectedPair: {},
                selectedCurrencyInput: '',
                oSelectedPair: {},
                oCurrencyInput: '',
                origin_supply: '',
                total: 0
            },
            selectedPair: {},
            value: 0,
        };
        const currencyDatasLoaded = !props.loadingCurrencies && props.translates['BTC'] && props.baseCurrencies['1'];
        if(currencyDatasLoaded) {
            this.state.inputs.origin_currency = Object.values(props.currencies).filter((pair) => ACCEPTED.includes(pair.buy_active))[0]['base']
            this.state.inputs.destination_currency = Object.values(props.currencies).filter((pair) => pair.base === this.state.inputs.origin_currency && ACCEPTED.includes(pair.buy_active))[0]['currency']
            this.state.inputs.selectedPair = Object.values(props.currencies).find((pair) => pair.currency == this.state.inputs.destination_currency && pair.base == this.state.inputs.origin_currency);
            this.state.inputs.origin_supply = getCurrencySupply(+this.state.inputs.origin_currency);
            console.log('loaded:', this.state.inputs.origin_currency, this.state.inputs.destination_currency);
        }
    }



    componentWillReceiveProps(nextProps, nextContext) {
        const inputs = this.state.inputs;
        if(!Object.keys(this.props.currencies).length && Object.keys(nextProps.currencies).length > 1) {

            inputs.selectedPair = Object.values(nextProps.currencies).find((pair) => ACCEPTED.includes(pair.buy_active));
            if(inputs.selectedPair){
                inputs.origin_currency = inputs.selectedPair.base
                inputs.destination_currency = inputs.selectedPair.currency;
            }
            inputs.origin_supply = getCurrencySupply(+this.state.inputs.origin_currency);
            this.setState({inputs: inputs});
            console.log('inputs.origin_currency:', inputs.origin_currency, ' destination_currency', inputs.destination_currency)
        }

    }

    validateFormValue = (name, value) => {
        value = fa2enNumbers(value);
        let {inputs, widget_back} = this.state;
        inputs[name] = value;
        // if(['origin_amount', 'destination_amount'].includes(name))
        //     inputs[name] = +value;
        switch (name) {
            case 'origin_currency':
            case 'destination_currency':
                if(this.props.baseCurrencies) {
                    inputs.selectedPair = this.props.currencies[currencySymbol(this.props.baseCurrencies, inputs.destination_currency) + currencySymbol(this.props.baseCurrencies, inputs.origin_currency)];
                    if(!inputs.selectedPair) {
                        inputs.destination_currency = Object.values(this.props.currencies).filter((pair) => pair.base == inputs.origin_currency && ACCEPTED.includes(pair.buy_active))[0]['currency']
                        inputs.selectedPair = this.props.currencies[currencySymbol(this.props.baseCurrencies, inputs.destination_currency) + currencySymbol(this.props.baseCurrencies, inputs.origin_currency)];
                    }
                    inputs.origin_supply = getCurrencySupply(+inputs.origin_currency);
                    console.log('origin_supply', inputs.origin_supply)
                }
                break;
            case 'origin_amount':
                inputs.destination_amount = inputs.selectedPair ? roundDown(inputs.origin_amount * inputs.selectedPair.buy, stepPrecision(this.props.baseCurrencies, inputs.destination_currency)) : 0;
                break;
            case 'destination_amount':
                inputs.origin_amount = inputs.selectedPair ? roundUp(inputs.destination_amount / inputs.selectedPair.buy, stepPrecision(this.props.baseCurrencies, inputs.origin_currency)) : 0;
                break;


        }
        console.log('selected pair:', inputs.selectedPair, 'origin', inputs.origin_currency, 'des', inputs.destination_currency);
        this.setState({inputs, widget_back});
        return true;
    };

    handleClick = (name, value) => {
        this.validateFormValue(name, value);

    };

    handleChange = (e) => {
        const {name, value} = e.target;
        this.validateFormValue(name, value);

    };

    onChange = (event, value) => {
        this.setState({value});
    };



    render() {
        const {value, inputs, selectedPair} = this.state;
        const {token, loadingCurrencies, currencies, currentUser, supplies, width} = this.props;
        const currencyDatasLoaded = !loadingCurrencies && this.props.translates['BTC'] && this.props.baseCurrencies['1'];
        const filteredBases = [];
        const filteredCurrencies = [];
        if(!currencyDatasLoaded) {
            return (
                <Card className="converter">
                    <fieldset disabled={loadingCurrencies} className="p-0">
                        <form autoComplete="off" className="row calc-form">
                            <div className="col-12 mb-3 px-2 calculator-loading">
                                <CircularProgress/>
                                <p className="w-100 text-center">در حال بارگذاری</p>
                            </div>
                        </form>
                    </fieldset>
                </Card>
                )

        }
        return (
            <Card className="converter">
                <Tabs
                    value={value}
                    onChange={this.onChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    scrollButtons="on"
                    className="jr-fs-lg converter-tab-wrapper"
                >
                    <Tab className="tab jr-fs-lg converter-tab buy" label={"خرید از ما"}
                         onClick={() => this.handleClick('conversion', 0)} name= 'conversion'  value={0}/>
                    <Tab className="tab jr-fs-lg converter-tab sell" label={"فروش به ما"} onClick={()=>this.handleClick('conversion', 1)}  name= 'conversion' value={1} />

                </Tabs>
                {value === 0 &&
                    <TabContainer>
                        <fieldset disabled={loadingCurrencies} className=" field-set-give">
                            {/*<div className="jr-card p-0 m-0">*/}
                            <form autoComplete="off" className="row" >
                                <div className="input-label">
                                    پرداخت میکنید
                                </div>
                                <div className=" select-row">
                                    <div className="coin-select-input">
                                        <Autocomplete
                                            PopperComponent={PopperMy}
                                            id="o-currency"
                                            // PopperComponent={PopperMy}
                                            // style={{ width: 300 }}
                                            value={inputs.selectedPair}
                                            inputValue={inputs.oCurrencyInput}
                                            // defaultValue={inputs.selectedPair}
                                            onOpen={() => {
                                                this.validateFormValue('oCurrencyInput', '');
                                            }
                                            }
                                            onInputChange={(event, newValue, reason) => {

                                                // if(reason !== 'reset')
                                                this.validateFormValue('oCurrencyInput', newValue);
                                            }}
                                            // defaultValue={inputs.selectedPair}
                                            options={Object.values(currencies).filter((pair) => {
                                                return (pair.base !== DIGITAL_CURRENCIES.WM) && ACCEPTED.includes(pair.buy_active) && !filteredBases.includes(pair.base) && (filteredBases.push(pair.base) || 1)
                                            })}
                                            // open={true}
                                            onChange={(event, newValue) => {
                                                this.validateFormValue('origin_currency', newValue.base);
                                            }}
                                            autoHighlight
                                            disableClearable
                                            getOptionLabel={(pair) => {
                                                return translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.base)) + " (" + currencySymbol(this.props.baseCurrencies, pair.base) + ")"
                                            }}
                                            renderOption={(pair) => (
                                                <React.Fragment>
                                                    <MenuItem className=" px-1 flex-shrink-1 flex-grow-1"
                                                              key={pair.base} value={pair.base}>
                                                        <div className="d-inline">
                                                            <div
                                                                className="coin-logo-converter mr-1 float-right"
                                                                style={{backgroundPositionY: ([pair.base] - 1) * -20}}/>
                                                            <div className=" ml-1">
                                                                {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.base))} ({currencySymbol(this.props.baseCurrencies, pair.base)})
                                                            </div>
                                                        </div>
                                                    </MenuItem>
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => {
                                                return (
                                                    <div className="selected-currency">
                                                        <TextField
                                                            className="give-text-field currency-input"
                                                            {...params}
                                                            label={<IntlMessages id="title.origin_currency"/>}
                                                            variant="outlined"
                                                            size="small"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <div className="coin-logo-converter  float-right"
                                                                             style={{backgroundPositionY: ([inputs.selectedPair.base] - 1) * -22}}/>

                                                                    </InputAdornment>
                                                                ),
                                                            }}

                                                        />
                                                    </div>

                                                )
                                            }}
                                        />

                                    </div>
                                    <div className="price-input">
                                        <TextField
                                            className="give-text-field price-input"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={'مقدار'}

                                            size='small'
                                            variant="outlined"
                                            id="search"
                                            onChange={this.handleChange}
                                            value={+inputs.origin_amount}
                                            name="origin_amount"
                                            // name="origin_currency"
                                            type="number"
                                            margin="normal"
                                            defaultValue={"25,500,000"}
                                            fullWidth
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 0,
                                            }}
                                        />

                                    </div>
                                </div>

                                <div className="field-set-bottom">
                                    <div>
                                        نرخ تبدیل
                                    </div>
                                    <span>
                                            {(+inputs.selectedPair.buy).toLocaleString()}
                                        </span>
                                </div>
                            </form>

                        </fieldset>
                        <fieldset disabled={loadingCurrencies} className=" field-set-get">
                            {/*<div className="jr-card p-0 m-0">*/}
                            <form autoComplete="off" className="row" >
                                <div className="input-label">
                                    دریافت میکنید
                                </div>
                                <div className="select-row">
                                    <div className="coin-select-input ">
                                        <Autocomplete
                                            PopperComponent={PopperMy}
                                            id="currency-select"
                                            // PopperComponent={PopperMy}
                                            // style={{ width: 300 }}
                                            value={inputs.selectedPair}
                                            inputValue={inputs.selectedCurrencyInput}
                                            // defaultValue={inputs.selectedPair}
                                            onOpen={() => {
                                                this.validateFormValue('selectedCurrencyInput', '');
                                            }
                                            }
                                            onInputChange={(event, newValue, reason) => {

                                                // if(reason !== 'reset')
                                                this.validateFormValue('selectedCurrencyInput', newValue);
                                            }}
                                            // defaultValue={inputs.selectedPair}
                                            options={Object.values(currencies).filter((pair) => {
                                                // if(ACCEPTED.includes(pair.buy_active) && !filteredCurrencies.includes(pair.currency) && pair.base == inputs.origin_currency && ((filteredCurrencies.push(pair.currency) || 1)))
                                                //     console.log('selected', pair)
                                                // else
                                                //     console.log('else:', pair, filteredCurrencies)
                                                // console.log('currency option:', ACCEPTED.includes(pair.buy_active), !filteredCurrencies.includes(pair.currency) , pair.base == inputs.origin_currency, inputs.origin_currency, filteredCurrencies)
                                                return ACCEPTED.includes(pair.buy_active) && !filteredCurrencies.includes(pair.currency) && pair.base == inputs.origin_currency && ((filteredCurrencies.push(pair.currency) || 1))

                                            })}
                                            // open={true}
                                            onChange={(event, newValue) => {
                                                this.validateFormValue('destination_currency', newValue.currency);
                                            }}
                                            autoHighlight
                                            disableClearable
                                            getOptionLabel={(pair) => {
                                                return translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency)) + " (" + currencySymbol(this.props.baseCurrencies, pair.currency) + ")"
                                            }}
                                            renderOption={(pair) => (
                                                <React.Fragment>
                                                    <MenuItem className=" px-1 flex-shrink-1 flex-grow-1"
                                                              key={pair.currency} value={pair.currency}>
                                                        <div className="d-inline">
                                                            <div
                                                                className="coin-logo-converter mr-1 float-right"
                                                                style={{backgroundPositionY: ([pair.currency] - 1) * -20}}/>
                                                            <div className=" ml-1">
                                                                {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency))} ({currencySymbol(this.props.baseCurrencies, pair.currency)})
                                                            </div>
                                                        </div>
                                                    </MenuItem>
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => {
                                                return (
                                                    <div className="selected-currency">

                                                        <TextField
                                                            className="currency-input"


                                                            {...params}
                                                            label={<IntlMessages
                                                                id="title.destination_currency"/>}
                                                            variant="outlined"
                                                            size="small"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <div className="coin-logo-converter  float-right"
                                                                             style={{backgroundPositionY: ([inputs.selectedPair.base] - 1) * -22}}/>

                                                                    </InputAdornment>
                                                                ),

                                                            }}
                                                        />
                                                    </div>

                                                )
                                            }}
                                        />
                                    </div>
                                    <div className="price-input">
                                        <TextField

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            className=" price-input"
                                            size='small'
                                            label={'مقدار'}
                                            variant="outlined"
                                            id="search"
                                            onChange={this.handleChange}
                                            // onKeyPress={e => {
                                            //     if (e.key === 'Enter') {
                                            //         this.props.switchChangeDialog({
                                            //             state: true,
                                            //             selectedCurrency: parseInt(inputs.destination_currency),
                                            //             initialAmount: inputs.origin_amount,
                                            //             selectedBase: +inputs.origin_currency
                                            //         })
                                            //     }
                                            // }}
                                            value={+inputs.destination_amount}
                                            name="destination_amount"
                                            type="number"
                                            // defaultValue={}
                                            margin="normal"
                                            fullWidth
                                            style={{
                                                marginTop: 0,
                                                marginBottom: 0,
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="field-set-bottom">
                                    <div>
                                        موجودی سایت (حداکثر خرید)
                                    </div>
                                    <span>
                                            {!supplies[inputs.destination_currency] ? '-' : supplies[inputs.destination_currency].toString().match((new RegExp('^-?\\d+(?:\.\\d{0,' + (Math.min(stepPrecision(this.props.baseCurrencies, inputs.destination_currency), 5) || -1) + '})?')))}
                                        </span>
                                </div>
                            </form>
                        </fieldset>
                        <div className="per-coin-price">
                            <div className="per-coin-title">
                                قیمت:
                            </div>
                            <div className="coin-wrapper">
                                <div className="coin-name">
                                    هر یک {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, inputs.origin_currency))} ≈
                                </div>
                                <div className="coin-value">
                                    <div className="value">
                                        {(+inputs.selectedPair.buy).toLocaleString()}
                                    </div>
                                    <div className="metric">
                                        {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, inputs.destination_currency))}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <Button
                                variant="contained"
                                className="converter-buy"
                                disabled={loadingCurrencies}
                                disableRipple
                                disableElevation
                                fullWidth
                                onClick={() => {
                                    if(token === null) {
                                        this.props.switchAuthDialog({state: true});
                                        this.props.authModeSelector("login");
                                    } else
                                        this.props.switchBuyDialog({
                                            state: true,
                                            selectedCurrency: inputs.destination_currency,
                                            initialAmount: inputs.currency_amount,
                                            origin_currency: inputs.origin_currency
                                        })
                                }}
                            >
                                ثبت سفارش
                            </Button>
                        </div>
                    </TabContainer>}
                {value === 1 &&
                    <TabContainer>
                        <fieldset disabled={loadingCurrencies} className=" field-set-give">
                            <form autoComplete="off" className="row" >
                                <div className="input-label ">
                                    پرداخت میکنید
                                </div>
                                <div className="select-row">
                                    <div className="coin-select-input">
                                        <TextField
                                            className=""
                                            size='small'
                                            variant="outlined"
                                            select
                                            value={inputs.destination_currency}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                name: 'destination_currency',
                                                id: 'demo-controlled',
                                            }}>
                                            <div className='currency-menu-header'>
                                                <div className="menu-header-item">ارز</div>
                                                <div className="menu-header-item">قیمت خرید (تومان)</div>
                                                <div className="menu-header-item">موجودی سایت</div>
                                            </div>

                                            {/*<PriceTable tradeControls/>*/}
                                            {Object.values(currencies).filter((pair) => ACCEPTED.includes(pair.buy_active)).map((pair, index) => (
                                                <MenuItem className="menu-item-wrapper currency-menu-item" key={pair.currency} value={pair.currency}
                                                          selected={index === 0}>
                                                    <div className="menu-item">
                                                        <div className="coin-logo-name-wrapper">
                                                            {width <= MOBILE_MAX_WIDTH ? (
                                                                <div className="coin-logo-converter" style={{width: '17px', height: '17px', backgroundPositionY: ([pair.currency] - 1) * -17}} />

                                                            ) : (
                                                                <div className="coin-logo-converter " style={{width: '24px', height: '24px', backgroundPositionY: ([pair.currency] - 1) * -24}} />

                                                            )}
                                                            <div  className="coin-name-converter">
                                                                <div className="coin-name">
                                                                    {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, pair.currency))}
                                                                </div>
                                                                <div className="coin-symbol">
                                                                    ({currencySymbol(this.props.baseCurrencies, pair.currency)})
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="coin-price" >
                                                            {(parseInt(pair.buy / 10)).toLocaleString()}
                                                        </div>
                                                        <div className="supply-value" >
                                                            {!supplies[pair.currency] ? '-' : supplies[pair.currency].toString().match((new RegExp('^-?\\d+(?:\.\\d{0,' + (Math.min(stepPrecision(this.props.baseCurrencies, pair.currency), 5) || -1) + '})?')))}
                                                        </div>
                                                    </div>
                                                </MenuItem>
                                            ))}


                                        </TextField>

                                    </div>
                                    <div className="price-input">
                                        <TextField
                                            placeholder={"0"}
                                            size='small'
                                            variant="outlined"
                                            id="search"
                                            onChange={this.handleChange}
                                            value={inputs.amount}
                                            name="amount"
                                            type="text"
                                            margin="normal"
                                            defaultValue={"25,500,000"}
                                            fullWidth

                                        />

                                    </div>
                                </div>

                                <div className="field-set-bottom">
                                    <div>
                                        موجودی کیف پول
                                    </div>
                                    <span>
                                            {token !== null ? parseInt(currentUser.wallet.balance / 10).toLocaleString() : ''}
                                        </span>
                                </div>

                            </form>

                        </fieldset>
                        <fieldset disabled={loadingCurrencies} className=" field-set-get">
                            <form autoComplete="off" className="row" >
                                <div className="input-label">
                                    دریافت میکنید
                                </div>
                                <div className="select-row">
                                    <div className="coin-select-input">
                                        <TextField
                                            size='small'
                                            variant="outlined"
                                            select
                                            disabled
                                            value={18}
                                            // onChange={this.handleChange}
                                            inputProps={{
                                                name: 'destination_currency',
                                                id: 'demo-controlled',
                                            }}>
                                            <div className='currency-menu-header'>
                                                <div className="menu-header-item">ارز</div>
                                                <div className="menu-header-item">قیمت خرید (تومان)</div>
                                                <div className="menu-header-item">موجودی سایت</div>
                                            </div>

                                            <MenuItem className="menu-item-wrapper currency-menu-item" key={18} value={18}
                                                      selected={true}>
                                                <div className="menu-item">
                                                    <div className="coin-logo-name-wrapper">
                                                        {width <= MOBILE_MAX_WIDTH ? (
                                                            <div className="coin-logo-converter" style={{width: '17px', height: '17px', backgroundPositionY: (18 - 1) * -17}} />

                                                        ) : (
                                                            <div className="coin-logo-converter " style={{width: '24px', height: '24px', backgroundPositionY: (18 - 1) * -24}} />

                                                        )}
                                                        <div  className="coin-name-converter">
                                                            <div className="coin-name">
                                                                {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, 18))}
                                                            </div>
                                                            <div className="coin-symbol">
                                                                ({currencySymbol(this.props.baseCurrencies, 18)})
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="coin-price" >

                                                    </div>
                                                    <div className="supply-value" >

                                                    </div>
                                                </div>
                                            </MenuItem>
                                        </TextField>
                                    </div>
                                    <div className="price-input">
                                        <TextField

                                            // placeholder={}
                                            size='small'
                                            variant="outlined"
                                            id="search"
                                            // disabled
                                            // onChange={this.handleChange}
                                            value={((selectedPair.sell * inputs.amount) / 10).toLocaleString()}
                                            name="amount"
                                            type="text"
                                            // defaultValue={"0.52525855"}
                                            margin="normal"
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className="field-set-bottom">
                                    <div>
                                        موجودی کیف پول
                                    </div>
                                    <span>
                                            {token !== null  ? parseInt(currentUser.wallet.balance / 10).toLocaleString() : ''}
                                        </span>
                                </div>

                            </form>
                        </fieldset>
                        <div className="per-coin-price">
                            <div className="per-coin-title">
                                قیمت:
                            </div>
                            <div className="coin-wrapper">
                                <div className="coin-name">
                                    هر یک {translate(this.props.translates, currencySymbol(this.props.baseCurrencies, inputs.destination_currency))} ≈
                                </div>
                                <div className="coin-value">
                                    <div className="value">
                                        {parseInt(selectedPair.sell / 10).toLocaleString()}
                                    </div>
                                    <div className="metric">
                                        تومان
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="">
                            <Button
                                variant="contained"
                                className="converter-buy"
                                disableRipple
                                disableElevation
                                disabled={loadingCurrencies}
                                fullWidth
                                onClick={() => {
                                    if(token === null) {
                                        this.props.switchAuthDialog({state: true});
                                        this.props.authModeSelector("login");
                                    } else
                                        this.props.switchBuyDialog({
                                            state: true,
                                            selectedCurrency: inputs.destination_currency,
                                            initialAmount: inputs.currency_amount,
                                            origin_currency: inputs.origin_currency
                                        })
                                }}
                            >
                                ثبت سفارش
                            </Button>
                        </div>
                    </TabContainer>
                }
            </Card>



        );
    }
};

const mapStateToProps = ({currency, auth, settings}) => {
    const {currentUser, token} = auth;
    const {width} = settings;
    const {currencies, supplies, loadingCurrencies, baseCurrencies, translates} = currency;
    const filteredCurrencies = Object.fromEntries(
        Object.entries(currencies).filter(([key, value]) => value.base !== DIGITAL_CURRENCIES.IRR) )
    return {currencies: filteredCurrencies, loadingCurrencies, baseCurrencies, translates, currentUser, token, supplies, width}
};
export default connect(mapStateToProps, {switchBuyDialog, switchSellDialog, authModeSelector, switchAuthDialog})(CurrencyConverter);
