import React from "react";
import Calculator from "./Calculator";
import GreetingSlider from "./GreetingSlider";
import ImageStorage from "../../../assets/ImageStorage";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {MOBILE_MAX_WIDTH} from "../../../panel/routes/data/constants";


class Greeting extends React.Component{
    render(){

        const {width} = this.props;
        return (
            <div className="landing-section">
                <div className="greeting-wrapper 12">
                    {/*<div className="greeting-wrapper-inner">*/}
                    {width > MOBILE_MAX_WIDTH ? (
                        <>
                            <div className="col-lg-4 greeting-slider-wrapper">
                                <GreetingSlider/>
                            </div>
                            {/*<img alt="alt" className="home-red-img" src={ImageStorage.homeBorder()}/>*/}
                        </>
                    ) : ''}

                    <div className={"col-lg-8 greeting-calculator-wrapper " + (width < MOBILE_MAX_WIDTH ? 'p-0 ' : '')}>
                        <Calculator/>
                    </div>
                    {/*</div>*/}

                </div>
            </div>

        )
    }

}

const mapStateToProps = ({settings}) => {
    const {width} = settings;
    return {width}
};
export default withRouter(connect(mapStateToProps)(Greeting));